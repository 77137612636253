import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';
import {BillService} from '../../../shared/services/bill.service';

@Component({
  selector: 'app-account-number-verify',
  templateUrl: './account-number-verify.component.html',
  styleUrls: ['./account-number-verify.component.scss']
})
export class AccountNumberVerifyComponent extends KioskComponent{
  // TODO: rename to accountNumberVerify and move to shared module

  isError = false;

  constructor(private billService: BillService,
              private sessionDataService: SessionDataService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              @Inject(PLATFORM_ID) platformId: string) {

    super(platformId);

    sessionDataService.getSessionData().pipe(
      takeUntil(this.destroy$),
      // map(data => {data?.accountNumber || '', data?.paymentType || ''}),
      // map(data => data),
      switchMap(data => billService.getSubscriberProfile({ban: data?.accountNumber || '', paymentType:  data?.paymentType})),
      catchError((err) => {
        this.isError = true;
        return NEVER;
      })
    ).subscribe((verificationData) => {
      if (verificationData.valid) {
        this.router.navigate(this.activatedRoute.snapshot.data.nextRoute, {relativeTo: this.activatedRoute});
      } else {
        this.isError = true;
      }
    });
  }


}
