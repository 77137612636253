<div class="flex-row content">
  <div>
    <h1>Bill details</h1>
    <p>Pay using phone or account number</p>
  </div>

  <div class="flex-column">
    <button routerLink="../phone" class="step-button">
      <span class="bold">Phone Number</span>
      <br>
      <span>Use a phone number to pay a bill</span>
    </button>

    <button routerLink="../account-number" class="step-button">
      <span>
        <span class="bold">Account Number</span>
        <br>
        <span>Use an account number to pay a bill</span>

      </span>
    </button>
  </div>
</div>

<app-bottom-actions [hideContinue]="true" [continueDisabled]="true" cancelText="Cancel"></app-bottom-actions>
