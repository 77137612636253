<div class="flex-row content">
  <div>
    <h2>Purchase Type</h2>
    <h3>Top Up your phone</h3>
  </div>

  <div class="flex-column">
    <button routerLink="funds" class="step-button">
      <span class="bold">Add Funds</span>
      <br>
      <span>Add credit to your mobile phone</span>
    </button>

    <button class="step-button" routerLink="bundles">
      <span>
        <span class="bold">Purchase a plan</span>
        <br>
        <span>Save with plan purchase</span>
      </span>
    </button>
  </div>
</div>

<app-bottom-actions [hideContinue]="true" [continueDisabled]="true" cancelText="Cancel"></app-bottom-actions>
