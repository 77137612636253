export interface PhoneModel {
  'subscriberType': SubscriberType;
  'valid': false;
}

export enum SubscriberType {
  'Postpaid',
  'PrePaid'
}

export interface PhoneTopUpRequest {
  'amount': {
    'value': number,
    'currency': string
  };
  'targetMSISDN': string;
  'providerName': Providers;
  'paymentType': string;
  'planRef': string;
}

export interface PhoneTopUpResponse {
  'id': number;
  'status': number;
  'message': string;
}

export enum Providers {
  DIGICEL_SIMULATE = 'DIGICEL_SIMULATE',
  DIGICEL = 'DIGICEL'
}


