
export interface FlagPrefix {
  name: string;
  prefix: string;
  countryCode: string,
}

export const FlagBundles: FlagPrefix[] = [
  {
    name: 'Anguilla',
    prefix: "1264",
    countryCode: "ai"
  },
  {
    name: 'Antigua',
    prefix: "1268",
    countryCode: "ag"
  },
  {
    name: 'Aruba',
    prefix: "1297",
    countryCode: "aw"
  },

  {
    name: 'Barbados',
    prefix: "1246",
    countryCode: "bb"
  },
  {
    name: 'Bermuda',
    prefix: "1441",
    countryCode: "bm"
  },
  {
    name: 'Bonaire',
    prefix: "5997",
    countryCode: "bq"
  },

  {
    name: 'British V.I.',
    prefix: "1284",
    countryCode: "vg"
  },
  {
    name: 'Cayman Islands',
    prefix: "1345",
    countryCode: "ky"
  },
  {
    name: 'Curacao',
    prefix: "5999",
    countryCode: "cw"
  },
  {
    name: 'Dominica',
    prefix: "1767",
    countryCode: "dm"
  },
  {
    name: 'El Salvador',
    prefix: "503",
    countryCode: "sv"
  },
  {
    name: 'Fiji',
    prefix: "679",
    countryCode: "fj"
  },
  {
    name: 'French Guiana',
    prefix: "594694",
    countryCode: "gf"
  },

  {
    name: 'Grenada',
    prefix: "1473",
    countryCode: "gd"
  },
  {
    name: 'Guadeloupe',
    prefix: "590690",
    countryCode: "gp"
  },

  {
    name: 'Guyana',
    prefix: "1592",
    countryCode: "gy"
  },
  {
    name: 'Haiti',
    prefix: "1509",
    countryCode: "ht"
  },
  {
    name: 'Jamaica',
    prefix: "1876",
    countryCode: "jm"
  },
  {
    name: 'Martinique',
    prefix: "596696",
    countryCode: "mq"
  },
  {
    name: 'Montserrat',
    prefix: "1664",
    countryCode: "ms"
  },
  {
    name: 'Nauru',
    prefix: "1674",
    countryCode: "nr"
  },
  {
    name: 'Panama',
    prefix: "507",
    countryCode: "pa"
  },
  {
    name: 'Papua N. Guinea',
    prefix: "675",
    countryCode: "pg"
  },
  {
    name: 'Samoa',
    prefix: "685",
    countryCode: "ws"
  },

  {
    name: 'St Kitts',
    prefix: "1869",
    countryCode: "kn"
  },
  {
    name: 'St Lucia',
    prefix: "1758",
    countryCode: "lc"
  },
  {
    name: 'St Vincent',
    prefix: "1784",
    countryCode: "vc"
  },
  {
    name: 'Suriname',
    prefix: "1597",
    countryCode: "sr"
  },
  {
    name: 'Tonga',
    prefix: "676",
    countryCode: "to"
  },

  {
    name: 'Trinidad',
    prefix: "1868",
    countryCode: "tt"
  }
  ,

  {
    name: 'Turks & Caicos',
    prefix: "1649",
    countryCode: "tc"
  },

  {
    name: 'Vanatu',
    prefix: "678",
    countryCode: "vu"
  }
];
