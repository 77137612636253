import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {WelcomeComponent} from './welcome/welcome.component';
import {TopUpHomeComponent} from './top-up-home/top-up-home.component';
import {PhoneNumberSectionComponent} from './top-up-home/phone-number-section/phone-number-section.component';
import {VerifyPhoneNumberComponent} from './top-up-home/verify-phone-number/verify-phone-number.component';
import {TopUpAmountComponent} from './top-up-home/top-up-amount/top-up-amount.component';
import {TopUpReviewComponent} from './top-up-home/top-up-review/top-up-review.component';
import {
  TopUpCardLoaderComponent,
  TransactionTypes
} from './top-up-home/top-up-card-loader/top-up-card-loader.component';
import {TopUpCardStatusComponent} from './top-up-home/top-up-card-status/top-up-card-status.component';
import {TopUpBundleSelectComponent} from './top-up-home/top-up-bundle-select/top-up-bundle-select.component';
import {TopUpBundleReviewComponent} from './top-up-home/top-up-bundle-review/top-up-bundle-review.component';
import {BillHomeComponent} from './bill-home/bill-home.component';
import {BillTypeComponent} from './bill-home/bill-type/bill-type.component';
import {BillReviewComponent} from './bill-home/bill-review/bill-review.component';
import {BillLoaderComponent} from './bill-home/bill-loader/bill-loader.component';
import {AccountNumberVerifyComponent} from './bill-home/account-number-verify/account-number-verify.component';
import {AccountNumberSectionComponent} from './account-number-section/account-number-section.component';
import {TopUpAmountBillComponent} from "./top-up-home/top-up-amount-bill/top-up-amount-bill.component";
import {FlagSelectComponent} from "./top-up-home/flag-select/flag-select.component";

export const contentRoutes: Routes = [
  {
    path: '',
    component: WelcomeComponent
  },
  {
    path: 'bill',
    children: [
      {
        path: '',
        component: BillHomeComponent
      },
      {
        path: 'select',
        component: BillTypeComponent
      },
      {
        path: 'phone',
        component: PhoneNumberSectionComponent,
        data: {
          title: 'Pay a bill',
          subtitle: 'Recipient phone number'
        }
      },
      {
        path: 'verify',
        component: VerifyPhoneNumberComponent,
        data: {
          nextRoute: ['..', 'bill-loader']
        }
      },
      {
        path: 'account-number',
        component: AccountNumberSectionComponent,
        data: {
          title: 'Pay a bill',
          subtitle: 'Enter account number or scan your bill'
        }
      },
      {
        path: 'verify-account',
        component: AccountNumberVerifyComponent,
        data: {
          nextRoute: ['..', 'bill-loader']
        }
      },
      {
        path: 'bill-loader',
        component: BillLoaderComponent
      },
      {
        path: 'review',
        component: BillReviewComponent
      },
      {
        path: 'amount',
        component: TopUpAmountComponent
      },
      {
        path: 'amount-bill',
        component: TopUpAmountBillComponent
      },

      {
        path: 'loader',
        component: TopUpCardLoaderComponent,
        data: {
          transactionType: TransactionTypes.MobileBill
        }
      },
      {
        path: 'status',
        component: TopUpCardStatusComponent
      }
    ]
  },
  {
    path: 'top-up',
    children: [
      {
        path: '',
        component: TopUpHomeComponent
      },
      {
        path: 'funds',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'phone'
          },
          {
            path: 'phone',
            component: PhoneNumberSectionComponent,
            data: {
              title: 'Top Up',
              subtitle: 'Please enter recipient phone number',
              overseasActivated: true,
              skipVerify: true
            }
          },
            {
            path: 'flag-select',
            component: FlagSelectComponent
          },
          {
            path: 'verify',
            component: VerifyPhoneNumberComponent,
            data: {
              nextRoute: ['..', 'amount']
            }
          },
          {
            path: 'amount',
            component: TopUpAmountComponent
          },
          {
            path: 'review',
            component: TopUpReviewComponent
          },
          {
            path: 'loader',
            component: TopUpCardLoaderComponent,
            data: {
              transactionType: TransactionTypes.TopUpFunds
            }
          },
          {
            path: 'status',
            component: TopUpCardStatusComponent
          }
        ]
      },
      {
        path: 'bundles',
        children: [
          {
            path: '',
            component: PhoneNumberSectionComponent,
            data: {
              title: 'Top Up',
              subtitle: 'Please enter recipient phone number'
            }
          },
          {
            path: 'verify',
            component: VerifyPhoneNumberComponent,
            data: {
              nextRoute: ['..', 'select']
            }
          },
          {
            path: 'select',
            component: TopUpBundleSelectComponent
          },
          {
            path: 'review',
            component: TopUpBundleReviewComponent
          },
          {
            path: 'loader',
            component: TopUpCardLoaderComponent,
            data: {
              transactionType: TransactionTypes.TopUpBundle
            }
          },
          {
            path: 'status',
            component: TopUpCardStatusComponent
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(contentRoutes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}
