<div class="flex-row content">
  <div *ngIf="!isError">
    <h1>Verifying, please wait </h1>
    <app-loader></app-loader>
  </div>

  <div *ngIf="isError">
    <h1>Error validating phone number - please make sure the number you entered is valid </h1>
    <app-bottom-actions [continueDisabled]="true"></app-bottom-actions>
  </div>
</div>
