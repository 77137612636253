import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {falseIfMissing} from "protractor/built/util";

@Component({
  selector: 'app-phone-number-section',
  templateUrl: './phone-number-section.component.html',
  styleUrls: ['./phone-number-section.component.scss']
})
export class PhoneNumberSectionComponent extends KioskComponent implements OnInit {

  phoneData = '';
  title = '';
  subtitle = '';
  overseasActivated = false;
  prefix = "1868";
  skipVerify = false;
  continueToWhere = "../verify";

  constructor(private sessionDataService: SessionDataService,
              @Inject(PLATFORM_ID) platformId: string,
              private activatedRoute: ActivatedRoute) {
    super(platformId);

    this.title = this.activatedRoute.snapshot.data.title;
    this.subtitle = this.activatedRoute.snapshot.data.subtitle;
    this.overseasActivated = this.activatedRoute.snapshot.data.overseasActivated || false;
    this.skipVerify = this.activatedRoute.snapshot.data.skipVerify || false;
    this.continueToWhere = this.skipVerify ? "../amount": "../verify";

  }

  ngOnInit(): void {
    this.sessionDataService
      .getSessionData()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((sessionData) => {
        if (sessionData){
          this.prefix = sessionData.prefix? sessionData.prefix : "";
          if (sessionData.phoneNumber){
            if (!this.phoneData.startsWith(sessionData.prefix? sessionData.prefix : "")){
              this.phoneData = sessionData.prefix ? sessionData.prefix : "1868";
            } else {
              this.phoneData = sessionData.prefix + sessionData.phoneNumber.substring(sessionData.prefix ? sessionData.prefix.length : 0);
            }
          } else {
            this.phoneData = sessionData.prefix  ? sessionData.prefix : "1868";
          }
        } else {
          this.phoneData = "";
        }
//        this.phoneData = sessionData ? sessionData.phoneNumber ? sessionData.phoneNumber :  "1868" : '';
      });
  }

  public updatePhoneNumber(text: string): void {
    this.sessionDataService.setSessionData({
      phoneNumber: text

    });
  }
}
