import {Component, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss']
})
export class KioskComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(@Inject(PLATFORM_ID) private platformId: string) {
  }

  public reload(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
