<div class="flex-row content">
  <div>
    <h1>Digicel</h1>
    <h1>Welcome</h1>
    <p class="subtitle">We've made it easier to stay connected with friends and family.</p>
  </div>

  <div class="flex-column">
    <button routerLink="top-up" class="step-button">
      <span class="bold">Mobile Top Up</span>
      <br>
      <span>Add credit or purchase a plan</span>
    </button>

    <button routerLink="bill" class="step-button">
      <span>
        <span class="bold">Pay a bill</span>
        <br>
        <span>Pay phone or Home Entertainment bill</span>
      </span>
    </button>
  </div>
</div>
