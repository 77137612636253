import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bottom-actions',
  templateUrl: './bottom-actions.component.html',
  styleUrls: ['./bottom-actions.component.scss']
})
export class BottomActionsComponent {
  @Input() continueLink = '';
  @Input() flagLink = '';
  @Input() hideCancel = false;
  @Input() hideContinue = false;
  @Input() overseasActivated = false;
  @Input() continueDisabled = false;
  @Input() cancelText = 'Cancel';
  @Input() continueText = 'Continue';
  @Input() overseasText = 'Cross-Border';

  cancel(): void {
    document.location.href = '/';
  }
}
