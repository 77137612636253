import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PhoneTopUpRequest, PhoneTopUpResponse, PhoneModel} from '../models/phone.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  constructor(private httpClient: HttpClient) {
  }

  public ping() {
    // wakeup for cloud run thing
    const endpoint = `${environment.endpoint}/ping`;
    return this.httpClient.get(endpoint ).subscribe();
  }

  public verifyPhoneNumber(phoneNumber: string, paymentType: string): Observable<PhoneModel>{
    const endpoint = `${environment.endpoint}/validate-phone`;
    const params = {msisdn: phoneNumber, paymentType: paymentType};
    return this.httpClient.get(endpoint, {params} ) as Observable<PhoneModel>;
  }

  public topUp(requestPayload: PhoneTopUpRequest): Observable<PhoneTopUpResponse> {
    const endpoint = `${environment.endpoint}/phone-topup`;
    return this.httpClient.post(endpoint, requestPayload ) as Observable<PhoneTopUpResponse>;
  }
}
