import {Component} from '@angular/core';
import {SessionDataService} from "../../shared/services/session-data.service";
import {BillType} from "../bill-home/bill-home.component";

@Component({
  selector: 'app-top-up-home',
  templateUrl: './top-up-home.component.html',
  styleUrls: ['./top-up-home.component.scss']
})
export class TopUpHomeComponent {

  constructor(private sessionDataService: SessionDataService) {
    this.sessionDataService.setSessionData({paymentType: "GSM_DEPOSIT"});
  }

}
