import {Component, ViewChild} from '@angular/core';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {NumpadComponent} from '../../../shared/components/numpad/numpad.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-top-up-amount',
  templateUrl: './top-up-amount.component.html',
  styleUrls: ['./top-up-amount.component.scss']
})
export class TopUpAmountComponent {
  amount = '$';
  currency = environment.hardcodedCurrencySymbol;

  // TODO: rename to amount component
  constructor(private sessionDataService: SessionDataService) {
    this.sessionDataService.setSessionData({planRef: "10"});
  }

  setAmount(value: string): void {
    this.amount = `$${value}`;
    this.updatePhoneNumber(value);
  }

  updatePhoneNumber(value: string): void {
    this.sessionDataService.setSessionData({amount: value});
  }
}
