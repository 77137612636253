<div class="flex-row content">
  <div>
    <h1>Bill payment</h1>
    <p>Select the amount you wish to pay</p>
  </div>

  <div class="flex-column">
    <app-numpad (ngModelChange)="updatePhoneNumber($event)" [minLength]="1" [(ngModel)]="amount"></app-numpad>
  </div>
</div>
<app-bottom-actions [continueDisabled]="
  (amount| stringToValue) < 0 || (amount|stringToValue) > 9999
" continueLink="../review"></app-bottom-actions>



