<section class="input">
  <div class="input-area">
    <div class="comp-input">
      <span style="position: absolute;
      padding-top: 70px; max-width: 100px; width: 2.5em"
            class="flag-icon flag-icon-{{overseasActivated ? this.prefixCountryCode : ''}}"></span>

      <input class="{{overseasActivated ? 'inputfieldflag' : 'inputfield'}}"  *ngIf="mask" [value]="value | mask: mask" [mask]="mask"/>
      <input class="inputfield" *ngIf="!mask" [value]="value"/>

      <div class="numeric-pad">
        <button [disabled]="areNumbersDisabled" (click)="addText('1')">1</button>
        <button [disabled]="areNumbersDisabled" (click)="addText('2')">2</button>
        <button [disabled]="areNumbersDisabled" (click)="addText('3')">3</button>

        <button [disabled]="areNumbersDisabled" (click)="addText('4')">4</button>
        <button [disabled]="areNumbersDisabled" (click)="addText('5')">5</button>
        <button [disabled]="areNumbersDisabled" (click)="addText('6')">6</button>

        <button [disabled]="areNumbersDisabled" (click)="addText('7')">7</button>
        <button [disabled]="areNumbersDisabled" (click)="addText('8')">8</button>
        <button [disabled]="areNumbersDisabled" (click)="addText('9')">9</button>

        <button [disabled]="isDecimalDisabled" *ngIf="showDecimal" (click)="addText('.')">.</button>
        <!--hack: used for spacing-->
        <button class="hide" *ngIf="!showDecimal">..</button>
        <button [class.expandedBtn]="!showDecimal" [disabled]="areNumbersDisabled" (click)="addText('0')">0</button>
        <button [disabled]="isBackspaceDisabled" class="backspace" (click)="removeText()">
          <img src="assets/icons/backspace.svg" alt="backspace">
        </button>
      </div>

    </div>
  </div>
</section>
