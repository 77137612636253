<div class="text-center">
  <h1>Bill Payment Review</h1>
<!--  <p>{{isHome? 'Home and Entertainment': 'Post Paid Mobile'}}</p>-->

  <table class="payment-summary">
    <tr *ngIf="isHome" class="font-medium" >
      <td>Name</td>
      <td> {{ accountName }}</td>
    </tr>

    <tr class="font-medium" *ngIf="phoneNumber">
      <td>Phone Number</td>
      <td> {{ phoneNumber | mask: "+0 000 000000000000000" }}</td>
    </tr>

    <tr class="font-medium" *ngIf="accountNumber">
      <td>Account Number</td>
      <td> {{ accountNumber }} </td>
    </tr>

    <tr class="font-small">
      <td>Previous Amount Outstanding</td>
      <td><label>{{amounts.previousAmountDue | currency: currency}}</label></td>
    </tr>

    <tr class="font-medium">
      <td>Current amount due</td>
      <td><label>{{amounts.amountToBePaid | currency: currency}}</label></td>
    </tr>

    <tr class="font-medium">
      <td>Amount to be paid</td>
      <td><label>{{amounts.remaining | currency: currency}}</label></td>
    </tr>
  </table>
</div>

<app-bottom-actions  [continueDisabled]="amounts.remaining === '0'" continueLink="../loader" continueText="Pay now">
  <button [disabled]="amounts.remaining === '0'" class="outline cancel" routerLink="../amount-bill" >Custom Amount</button>
</app-bottom-actions>
