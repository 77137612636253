import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {StringToValuePipe} from '../../../shared/pipes/string-to-value.pipe';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';
import {takeUntil} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

// TODO: copy pasted - put in shared utils
type Nullable<T> = T | undefined | null;

@Component({
  selector: 'app-top-up-review',
  templateUrl: './top-up-review.component.html',
  styleUrls: ['./top-up-review.component.scss']
})
export class TopUpReviewComponent extends KioskComponent {

  amountPipe = new StringToValuePipe();

  phoneNumber: Nullable<string> = null;
  accountNumber: Nullable<string> = null;
  currency = environment.hardcodedCurrencySymbol + ' ';

  amounts = {
    pretax: 0,
    taxes: 0,
    postTax: 0
  };

  constructor(private sessionDataService: SessionDataService,
              @Inject(PLATFORM_ID) platformId: string) {

    super(platformId);

    this.sessionDataService.getSessionData().pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.accountNumber = data?.accountNumber || null;
      this.phoneNumber = data?.phoneNumber || null;
      const amountNumber = this.amountPipe.transform(data?.amount || '0');

      // TODO: very likely to have floating point errors. Done as a quick solution
      this.amounts.pretax = amountNumber;
      this.amounts.postTax = Number((amountNumber * 1.125 ).toFixed(2));
      this.amounts.taxes = Number((this.amounts.postTax - this.amounts.pretax).toFixed(2));
    });
  }

}
