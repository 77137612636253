import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ContentModule} from './content/content.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthInterceptor} from "./shared/services/auth-interceptor";
// import {MomentModule} from "moment";
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    ContentModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
