<div>
  <h1>Plans</h1>
  <p>Select a bundle</p>

  <div class="flex-row wrap" style=" flex-grow:1; flex-shrink: 0; min-height: 50vh">
    <button class="step-button" *ngIf="index !== 0" (click)="index = index -1"><span class="bold">Previous Bundles</span></button>
    <button class="step-button" (click)="selectBundle(bundle)" *ngFor="let bundle of bundlesOnScreen()">
      <span class="bold">{{bundle.name}}</span>
    </button>
    <button class="step-button" *ngIf="showNextBtn()" (click)="index = index + 1">
      <span class="bold">More Bundles</span>
    </button>
  </div>


  <div class="flex-row" style=" flex-shrink: 0;">
  <app-bottom-actions [hideContinue]="true" [continueDisabled]="true" cancelText="Cancel"></app-bottom-actions>
  </div>
</div>
