import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {PhoneService} from '../../shared/services/phone.service';
import {SessionDataService} from "../../shared/services/session-data.service";
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {

  constructor(private phoneService: PhoneService) {
    phoneService.ping()
  }
}
