<div class="flex-row content">
  <div>
    <h1>{{title}}</h1>
    <p>{{subtitle}}</p>
  </div>

  <div class="flex-column">
    <app-numpad
      [(ngModel)]="accountNumber"
      (ngModelChange)="updateAccountNumber($event)"
      [showDecimal]="false"
      [maxLength]="13"
      [minDigits]="0"
      [maxDigits]="13"
    ></app-numpad>
  </div>
</div>

<app-bottom-actions [continueDisabled]="accountNumber.length < 12 || accountNumber.length > 13" continueLink="../verify-account"></app-bottom-actions>
