import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {KioskComponent} from "../../../shared/components/kiosk/kiosk.component";
import {SessionDataService} from "../../../shared/services/session-data.service";
import {BillService} from "../../../shared/services/bill.service";
import {switchMap, take, takeUntil} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-bill-loader',
  templateUrl: './bill-loader.component.html',
  styleUrls: ['./bill-loader.component.scss']
})
export class BillLoaderComponent extends KioskComponent {

  phoneNumber = '';
  accountNumber = '';

  amounts = {
    amountDue: '',
    amountToBePaid: '',
    remaining: ''
  };

  constructor(
    private sessionDataService: SessionDataService,
    private billService: BillService,
    @Inject(PLATFORM_ID) platformId: string,
    router: Router,
    activatedRoute: ActivatedRoute
  ) {

    super(platformId);

    sessionDataService.getSessionData()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        switchMap(data => billService.getSubscriberProfile({
          ban: data?.accountNumber || '',
          msisdn: data?.phoneNumber || '',
          paymentType: data?.paymentType || ''
        }))
      ).subscribe((d => {
      this.sessionDataService.setSessionData({accountName: d.accountName,
        bill: {outstandingAmount: d.lastInvoice.outstandingAmount + ''},
        lastInvoice: d.lastInvoice,
        });
      router.navigate(['..', 'review'], {relativeTo: activatedRoute});
    }));
  }
}
