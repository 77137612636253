import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {catchError, delay, switchMap, take, tap} from 'rxjs/operators';
import { PhoneService } from '../../../shared/services/phone.service';
import { SessionDataService } from '../../../shared/services/session-data.service';
import { Providers } from '../../../shared/models/phone.model';
import { StringToValuePipe } from '../../../shared/pipes/string-to-value.pipe';
import { KioskComponent } from '../../../shared/components/kiosk/kiosk.component';
import { BillService } from '../../../shared/services/bill.service';
import { environment } from '../../../../environments/environment';
import {of} from "rxjs";

@Component({
  selector: 'app-top-up-card-loader',
  templateUrl: './top-up-card-loader.component.html',
  styleUrls: ['./top-up-card-loader.component.scss']
})
export class TopUpCardLoaderComponent extends KioskComponent {
  // TODO: rename to TransactionLoaderComponent

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private phoneService: PhoneService,
              private sessionDataService: SessionDataService,
              private billService: BillService,
              @Inject(PLATFORM_ID) platformId: string) {
    super(platformId);
    const transactionType: TransactionTypes = this.activatedRoute.snapshot.data.transactionType;

    switch (transactionType) {
      case TransactionTypes.TopUpFunds:
        this.createBundleTopUp();
        break;
      case TransactionTypes.TopUpBundle:
        this.createBundleTopUp();
        break;
      case TransactionTypes.MobileBill:
        this.createMobileBill();
        break;
    }
  }

  stringToValuePipe = new StringToValuePipe();


  createTopUp(): void {
    this.sessionDataService.getSessionData().pipe(
      take(1),
      switchMap(sessionData => this.phoneService.topUp({
        amount: {
          value: this.stringToValuePipe.transform(sessionData?.amount || '0'),
          currency: 'TTD'
        },
        targetMSISDN: sessionData?.phoneNumber || '0',
        providerName: environment.simulation ? Providers.DIGICEL_SIMULATE : Providers.DIGICEL,
        paymentType: sessionData?.paymentType || '',
        planRef: sessionData?.planRef || '',
      })),
      catchError(error => {
        console.log("Caught error");
        return of(error);
      }),
      tap((response) => this.sessionDataService.setSessionData({transactionSuccessful: response.status === 0})),
      // delay(5000)
    ).subscribe(() => {
      this.router.navigate(['..', 'status'], {relativeTo: this.activatedRoute});
    });
  }

  // TODO: integrate API once it is implemented. right now we are creating a topup with the bundle value
  createBundleTopUp(): void {
    this.createTopUp();
  }

  createMobileBill(): void {

    this.sessionDataService.getSessionData().pipe(
      take(1),
      switchMap(sessionData => this.billService.payBill({
        amount: {
          value: this.stringToValuePipe.transform(sessionData?.amount || '0'),
          currency: 'TTD'
        },
        msisdn: sessionData?.phoneNumber || '',
        billingAccountNumber: sessionData?.accountNumber || '',
        cardAuthNumber: '0',
        paymentType: sessionData?.paymentType || '',
      })),
      catchError(error => {
        console.log("Caught error");
        return of(error);
      }),
      tap((response) => this.sessionDataService.setSessionData({transactionSuccessful: response.successful})),
      // delay(5000)
    ).subscribe(() => {
      this.router.navigate(['..', 'status'], {relativeTo: this.activatedRoute});
    });
  }
}

export enum TransactionTypes {
  TopUpFunds = 'TopUpFunds',
  TopUpBundle = 'TopUpBundle',
  MobileBill = 'MobileBill'
}
