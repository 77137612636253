import {Injectable} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import { v4 as uuid } from 'uuid';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    let kioskId = localStorage.getItem("kiosk-id");
    if (kioskId === undefined || kioskId == null){
      kioskId = uuid();
      localStorage.setItem("kiosk-id", kioskId);
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('kiosk-id', kioskId)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
