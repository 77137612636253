import {Injectable} from '@angular/core';
import {BillModel, PayBillParams, PayBillResponse, SubscriberProfileRequest} from '../models/bill.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(private httpClient: HttpClient) {
  }

  public getSubscriberProfile(requestParams: SubscriberProfileRequest): Observable<BillModel>{
    const endpoint =  `${environment.endpoint}/subscription-profile`;
    const params = {msisdn: requestParams.msisdn || '', ban: requestParams.ban || '', paymentType: requestParams.paymentType || ''};
    return this.httpClient.get(endpoint, {params} ) as Observable<BillModel>;
  }

  public payBill(requestParams: PayBillParams): Observable<PayBillResponse> {
    const endpoint = `${environment.endpoint}/bill-payment`;
    return this.httpClient.post(endpoint, requestParams ) as Observable<PayBillResponse>;
  }
}
