import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToValue'
})
export class StringToValuePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): number {
    return Number(value.replace(/[^0-9.-]+/g, ''));
  }

}
