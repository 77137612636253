import {
  AfterViewInit,
  Component,
  forwardRef,
  HostListener, Inject,
  Input,
  OnChanges,
  OnInit, PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SessionDataService} from "../../services/session-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {KioskComponent} from "../kiosk/kiosk.component";

type Nullable<T> = T | undefined | null;

// TODO: fix control-value-accessor - typing directly in the field does not work
@Component({
  selector: 'app-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumpadComponent),
      multi: true
    }
  ]
})
export class NumpadComponent  extends KioskComponent implements OnInit, ControlValueAccessor {
  @Input() minLength = 0;
  @Input() maxLength = 999;
  @Input() showDecimal = true;
  @Input() minDigits = 0;
  @Input() maxDigits = 11;
  @Input() mask: Nullable<string> = null;

  areNumbersDisabled = false;
  isBackspaceDisabled = false;
  isDecimalDisabled = false;
  value = '';
  prefixCountryCode = '';
  overseasActivated = false;

  constructor( @Inject(PLATFORM_ID) platformId: string,
               private sessionDataService: SessionDataService,
               private activatedRoute: ActivatedRoute
            ) {
    super(platformId);
    this.overseasActivated = this.activatedRoute.snapshot.data.overseasActivated   || false;
  }


  ngOnInit(): void {
    this.updateFormValidation();
    // update without triggering event

    this.sessionDataService
      .getSessionData()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((sessionData) => {
        this.prefixCountryCode = sessionData ? sessionData.prefixCountryCode ? sessionData.prefixCountryCode : '' : '';
        if (this.prefixCountryCode == '') {
            this.overseasActivated = false;
        }
      });
  }

  // TODO: don't allow preceding 0s?
  addText(newChar: string): void {

    if (this.value.length < this.maxDigits) {
      this.writeValue(this.value + newChar);
    }
    // window.navigator.vibrate(100);
  }

  removeText(): void {
    if (this.value.length > this.minDigits) {
      this.writeValue(this.value.substring(0, this.value.length - 1));
    }
    // window.navigator.vibrate(100);
  }

  public updateFormValidation(newVal = this.value): void {
    const textAfterDecimal = /\..+/g.exec(newVal);

    // When the max number is reached, disable - unless there is a dot in the text
    // when last character is dot, disable after 2 more numbers

    if (this.value && this.value.length) {
      this.areNumbersDisabled =
        (this.value.length === this.maxLength && this.value.indexOf('.') === -1)
        || !!(textAfterDecimal && textAfterDecimal[0].length >= 3);


      this.isBackspaceDisabled = !!this.value.length && this.value.length === this.minLength;
      this.isDecimalDisabled = this.value.length === this.maxLength || this.value.indexOf('.') !== -1;
    } else {
      this.isBackspaceDisabled = true;
    }
  }

  @HostListener('document:keydown', ['$event'])
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = event.key;
    if (event.key == "Backspace"){
      this.removeText();
    }
    if (pattern.test(inputChar)) {
      this.addText(event.key);

    }
    event.preventDefault();

  }

  writeValue(newValue: string): void{
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
    this.updateFormValidation();
  }

  onChange = (_: string) => {};
  onTouched = () => {};
  registerOnChange(fn: (_: string) => void): void {this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}
