<div>
  <h1>Country</h1>

  <div class="flex-row wrap">
    <button class="step-button" *ngIf="index !== 0" (click)="index = index -1"><span class="bold">Previous Countries</span></button>
    <button class="step-button" style="padding-left: 24px" (click)="selectBundle(bundle)" *ngFor="let bundle of bundlesOnScreen()">
      <span style=" display: inline-block; width:3em; line-height: 3em" class="flag-icon flag-icon-{{bundle.countryCode}}"></span>
      <span class="bold" style="margin-left: 15px; display: inline-block;">{{bundle.name}}</span>
    </button>
    <button class="step-button" *ngIf="showNextBtn()" (click)="index = index + 1">
      <span class="bold">More Countries</span>
    </button>
  </div>

</div>
