import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-top-up-card-status',
  templateUrl: './top-up-card-status.component.html',
  styleUrls: ['./top-up-card-status.component.scss']
})
export class TopUpCardStatusComponent extends KioskComponent {
  // TODO: rename to TransactionStatusComponent

  isSuccess = false;

  constructor(private sessionDataService: SessionDataService,
              @Inject(PLATFORM_ID) platformId: string) {
    super(platformId);

    sessionDataService.getSessionData().pipe(
      takeUntil(this.destroy$)
    ).subscribe((sessionData) => {
      this.isSuccess = sessionData?.transactionSuccessful || false;

      (window as any).setTimeout( () => { document.location.href = '/'; }, 10000);
    });
  }
}
