import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {PhoneService} from '../../../shared/services/phone.service';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NEVER} from 'rxjs';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';

@Component({
  selector: 'app-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss']
})
export class VerifyPhoneNumberComponent extends KioskComponent{

  isError = false;

  constructor(private phoneService: PhoneService,
              private sessionDataService: SessionDataService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              @Inject(PLATFORM_ID) platformId: string) {

    super(platformId);

    sessionDataService.getSessionData().pipe(
      takeUntil(this.destroy$),
      switchMap(data  => phoneService.verifyPhoneNumber(data?.phoneNumber || '', data?.paymentType || '')),
      catchError((err) => {
        this.isError = true;
        return NEVER;
      })
    ).subscribe((verificationData) => {
      if (verificationData.valid) {
        this.router.navigate(this.activatedRoute.snapshot.data.nextRoute, {relativeTo: this.activatedRoute});
      } else {
        this.isError = true;
      }
    });
  }

}
