<div class="flex-row content">
  <div>
    <h1>{{title}}</h1>
    <p>{{subtitle}}</p>
  </div>

  <div class="flex-column">
    <app-numpad
      [(ngModel)]="phoneData"
      (ngModelChange)="updatePhoneNumber($event)"
      [showDecimal]="false"
      [minDigits]=prefix.length
      [maxDigits]="11"
      mask="+0 000 000 0000"
    ></app-numpad>
  </div>
</div>

<app-bottom-actions [continueDisabled]="phoneData.length !== 11" [overseasActivated]=overseasActivated flagLink="../flag-select" continueLink={{continueToWhere}}>
</app-bottom-actions>

