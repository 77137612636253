import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {KioskComponent} from '../../shared/components/kiosk/kiosk.component';
import {SessionDataService} from '../../shared/services/session-data.service';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-account-number-section',
  templateUrl: './account-number-section.component.html',
  styleUrls: ['./account-number-section.component.scss']
})
export class AccountNumberSectionComponent extends KioskComponent implements OnInit {

  // TODO: move to shared module
  accountNumber = '';
  title = '';
  subtitle = '';

  constructor(private sessionDataService: SessionDataService,
              @Inject(PLATFORM_ID) platformId: string,
              private activatedRoute: ActivatedRoute) {
    super(platformId);

    this.title = this.activatedRoute.snapshot.data.title;
    this.subtitle = this.activatedRoute.snapshot.data.subtitle;
  }

  ngOnInit(): void {
    this.sessionDataService
      .getSessionData()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((sessionData) => {
        this.accountNumber = sessionData ? sessionData.accountNumber ? sessionData.accountNumber : '' : '';
      });
  }

  public updateAccountNumber(text: string): void {
    this.sessionDataService.setSessionData({
      accountNumber: text
    });
  }
}
