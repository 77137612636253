<div class="flex-row content">
  <div>
    <h1>Postpaid bill payment</h1>
    <p>Select an option</p>
  </div>

  <div class="flex-column">
    <button routerLink="select"
            (click)="updateBillSelection(billType.Mobile)"
            class="step-button">
      <span class="bold">Post paid mobile</span>
      <br>
      <span>Pay your mobile phone bill</span>
    </button>

    <button routerLink="account-number" (click)="updateBillSelection(billType.Home)" class="step-button">
      <span>
        <span class="bold">Home and Entertainment</span>
        <br>
        <span>Pay your home and entertainment bill</span>
      </span>
    </button>
  </div>


</div>

<app-bottom-actions [hideContinue]="true" [continueDisabled]="true" cancelText="Cancel"></app-bottom-actions>


