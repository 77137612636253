import {Component} from '@angular/core';
import {BillBundles, Bundle} from '../../../shared/models/topup.model';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-top-up-bundle-select',
  templateUrl: './top-up-bundle-select.component.html',
  styleUrls: ['./top-up-bundle-select.component.scss']
})
export class TopUpBundleSelectComponent {
  bundles = Object.freeze(BillBundles);
  maxItemsOnPage = 5;
  index = 0;

  constructor(private sessionDataService: SessionDataService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  // TODO: move to service for pagination and possible API integration
  // TODO: replace manual pagination with calculated min and max
  bundlesOnScreen(): Bundle[] {
    let nextMinIndex = 0;
    let nextMaxIndex = 0;

    switch (this.index) {
      case 0:
        nextMinIndex = 0;
        nextMaxIndex = 4;
        break;
      case 1:
        nextMinIndex = 4;
        nextMaxIndex = 9;
        break;
      case 2:
        nextMinIndex = 13;
        nextMaxIndex = 19;
        break;
    }

    return this.bundles.slice(nextMinIndex, nextMaxIndex);
  }

  showNextBtn(): boolean {
    return (this.index + 1) * this.maxItemsOnPage < this.bundles.length;
  }

  calculatePostTax(price: number): number {
    return Number((price * 1.125).toFixed(2))
  }

  calculateTax(price: number, postTax: number): number {
    return Number((postTax - price).toFixed(2))
  }

  selectBundle(bundle: Bundle): void {
    this.sessionDataService.setSessionData({
      planRef: bundle.planRef,
      amount: bundle.price + '',
      postTax: this.calculatePostTax(bundle.price) + '',
      tax: this.calculateTax(bundle.price, this.calculatePostTax(bundle.price)  ) + ''

    });

    this.router.navigate(['..', 'review'], {relativeTo: this.activatedRoute});
  }

}
