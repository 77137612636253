import { Component, OnInit } from '@angular/core';
import {SessionDataService} from '../../shared/services/session-data.service';

@Component({
  selector: 'app-bill-home',
  templateUrl: './bill-home.component.html',
  styleUrls: ['./bill-home.component.scss']
})
export class BillHomeComponent {
  public billType = BillType;

  constructor(private sessionDataService: SessionDataService) {
  }

  updateBillSelection(billType: BillType): void {
    // "FTTH_BILL_PAYMENT"

    if (billType === BillType.Home) {
      this.sessionDataService.setSessionData({
        isHome: billType === BillType.Home,
        paymentType: "FTTH_BILL_PAYMENT"
      });

    } else {
      this.sessionDataService.setSessionData({isHome: false, paymentType: "GSM_DEPOSIT"});

    }
  }
}

export enum BillType {
  Home,
  Mobile
}
