
export interface Bundle {
  name: string;
  price: number;
  planRef: string;
}

export const BillBundles: Bundle[] = [

  {
    name: 'Freedom 349',
    price: 349,
    planRef: '18754'
  },
  {
    name: 'Freedom 299',
    price: 299,
    planRef: '18739'
  },
  {
    name: 'Freedom 249',
    price: 249,
    planRef: '18738'
  },
  {
    name: 'Freedom 199',
    price: 199,
    planRef: '18737'
  }
];
