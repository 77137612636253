import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {BillService} from '../../../shared/services/bill.service';
import {takeUntil} from 'rxjs/operators';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';
import {StringToValuePipe} from '../../../shared/pipes/string-to-value.pipe';
import {environment} from '../../../../environments/environment';

// TODO: copy pasted - put in shared utils
type Nullable<T> = T | undefined | null;

@Component({
  selector: 'app-bill-review',
  templateUrl: './bill-review.component.html',
  styleUrls: ['./bill-review.component.scss']
})
export class BillReviewComponent extends KioskComponent {

  stringToValuePipe = new StringToValuePipe();
  currency = environment.hardcodedCurrencySymbol;

  phoneNumber: Nullable<string> = '';
  accountName: Nullable<string> = '';
  accountNumber: Nullable<string> = '';
  amounts = {
    previousAmountDue: '',
    amountToBePaid: '',
    remaining: ''
  };


  isHome = false;

  constructor(
    private sessionDataService: SessionDataService,
    private billService: BillService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    super(platformId);

    sessionDataService.getSessionData()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((data) => {
      this.phoneNumber = data?.phoneNumber;
      this.accountNumber = data?.accountNumber;
      this.accountName = data?.accountName;
      this.isHome = data?.isHome || false;

      if (data  && data.bill && data.bill.outstandingAmount === '0') {
       // const amountDue = (Math.abs(Number(data.bill.outstandingAmount)) * 1.125) + '';

        this.amounts = {
          previousAmountDue: '0',
          amountToBePaid: data.lastInvoice?.invoiceAmount  + '' || '0',
          remaining: '0',
        };

        this.sessionDataService.setSessionData({amount: data.lastInvoice?.invoiceAmount + ''});

      } else if (data && data.bill) {
        // const amountNum = data.lastInvoice?.invoiceAmount || 0;
        // const outstandingAmountNum = (Math.abs(Number(data.bill.outstandingAmount)) * 1.125);

        this.sessionDataService.setSessionData({amount: data.lastInvoice?.invoiceAmount + ''});

        this.amounts = {
          previousAmountDue: data.lastInvoice?.previousBalanceAmount + '',
          amountToBePaid: Number(data.lastInvoice?.currentAmount) + '',
          remaining: data.lastInvoice?.invoiceAmount + ''
        };
      }
    });
  }
}
