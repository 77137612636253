<div class="text-center">
  <h1>Top Up Payment Review</h1>
  <h3>Payment Summary</h3>

  <table class="payment-summary">
    <tr class="font-medium" *ngIf="phoneNumber">
      <td>Phone Number</td>
      <td> {{ phoneNumber | mask: "+0 000 000000000000000" }}</td>
    </tr>

    <tr class="font-medium" *ngIf="accountNumber">
      <td>Account Number</td>
      <td> {{ accountNumber }} </td>
    </tr>

    <tr class="font-small">
      <td>Amount Due</td>
      <td><label>{{amounts.pretax | currency:currency }}</label></td>
    </tr>

    <tr class="font-small">
      <td>Taxes</td>
      <td><label>{{amounts.taxes | currency:currency }}</label></td>
    </tr>

    <tr class="font-medium">
      <td>Total due</td>
      <td> {{ amounts.postTax | currency:currency }}</td>
    </tr>
  </table>
</div>

<app-bottom-actions continueLink="../loader"></app-bottom-actions>
