import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {StringToValuePipe} from '../../../shared/pipes/string-to-value.pipe';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {takeUntil} from 'rxjs/operators';
import {KioskComponent} from '../../../shared/components/kiosk/kiosk.component';
import { environment } from '../../../../environments/environment';

// TODO: copy pasted - put in shared utils
type Nullable<T> = T | undefined | null;

@Component({
  selector: 'app-top-up-bundle-review',
  templateUrl: './top-up-bundle-review.component.html',
  styleUrls: ['./top-up-bundle-review.component.scss']
})
export class TopUpBundleReviewComponent extends KioskComponent {

  amountPipe = new StringToValuePipe();

  phoneNumber: Nullable<string> = null;
  accountNumber: Nullable<string> = null;

  amount = 0;
  postTax = 0;
  tax = 0;
  currency = environment.hardcodedCurrencySymbol;

  constructor(private sessionDataService: SessionDataService,
              @Inject(PLATFORM_ID) platformId: string) {

    super(platformId);

    this.sessionDataService.getSessionData().pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.accountNumber = data?.accountNumber || null;
      this.phoneNumber = data?.phoneNumber || null;
      this.amount = this.amountPipe.transform(data?.amount || '0');
      this.tax = this.amountPipe.transform(data?.tax || '0');
      this.postTax = this.amountPipe.transform(data?.postTax || '0');
    });
  }


}
