<div class="flex-row content">
  <div>
    <h1>Top up amount</h1>
    <p>Select your top up amount</p>

    <div class="default-options">
      <button class="step-button" (click)="setAmount('35')"> {{currency}} 35</button>
      <button class="step-button" (click)="setAmount('95')">{{currency}} 95</button>
      <button class="step-button" (click)="setAmount('150')">{{currency}} 150</button>
      <button class="step-button" (click)="setAmount('200')">{{currency}} 200</button>
      <button class="step-button" (click)="setAmount('250')">{{currency}} 250</button>
      <button class="step-button" (click)="setAmount('300')">{{currency}} 300</button>
      <button class="step-button" (click)="setAmount('400')">{{currency}} 400</button>
      <button class="step-button" (click)="setAmount('500')">{{currency}} 500</button>
      <button class="step-button" (click)="setAmount('600')">{{currency}} 600</button>
    </div>
  </div>

  <div class="flex-column">
    <app-numpad (ngModelChange)="updatePhoneNumber($event)" [minLength]="1" [(ngModel)]="amount"></app-numpad>
  </div>
</div>
<app-bottom-actions [continueDisabled]="
  (amount|stringToValue) < 20 || (amount|stringToValue) > 1000
" continueLink="../review"></app-bottom-actions>



