import {Component} from '@angular/core';
import {BillBundles, Bundle} from '../../../shared/models/topup.model';
import {SessionDataService} from '../../../shared/services/session-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlagBundles, FlagPrefix} from "../../../shared/models/flag.model";

@Component({
  selector: 'app-top-up-bundle-select',
  templateUrl: './flag-select.component.html',
  styleUrls: ['./flag-select.component.scss']
})
export class FlagSelectComponent {
  bundles = Object.freeze(FlagBundles);
  maxItemsOnPage = 10;
  index = 0;


  constructor(private sessionDataService: SessionDataService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  // TODO: move to service for pagination and possible API integration
  // TODO: replace manual pagination with calculated min and max
  bundlesOnScreen(): FlagPrefix[] {
    let nextMinIndex = 0;
    let nextMaxIndex = 0;

    switch (this.index) {
      case 0:
        nextMinIndex = 0;
        nextMaxIndex = 9;
        break;
      case 1:
        nextMinIndex = 9;
        nextMaxIndex = 17;
        break;
      case 2:
        nextMinIndex = 17;
        nextMaxIndex = 32;
        break;
    }

    return this.bundles.slice(nextMinIndex, nextMaxIndex);
  }

  showNextBtn(): boolean {
    return (this.index + 1) * this.maxItemsOnPage < this.bundles.length;
  }

  selectBundle(flag: FlagPrefix): void {
    this.sessionDataService.setSessionData({
      prefix: flag.prefix,
      prefixName: flag.name,
      prefixCountryCode: flag.countryCode
    });

    this.router.navigate(['..', 'phone'], {relativeTo: this.activatedRoute});
  }

}
