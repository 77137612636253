import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumpadComponent } from './components/numpad/numpad.component';
import { KioskComponent } from './components/kiosk/kiosk.component';
import {NgxMaskModule} from 'ngx-mask';
import {HttpClientModule} from '@angular/common/http';
import { StringToValuePipe } from './pipes/string-to-value.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { BottomActionsComponent } from './bottom-actions/bottom-actions.component';
import { AppModule } from '../app.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NumpadComponent,
    KioskComponent,
    StringToValuePipe,
    LoaderComponent,
    BottomActionsComponent
  ],
  exports: [
    NumpadComponent,
    StringToValuePipe,
    LoaderComponent,
    BottomActionsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    NgxMaskModule.forRoot()
  ]
})
export class SharedModule { }
