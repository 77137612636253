<ng-template #failure>
  <h1>Oh No!</h1>

  <p>Your transaction was unsuccessful. Please try again.</p>
</ng-template>

<ng-template #success>
  <h1>Thank you!</h1>
  <p>Your transaction was successful. You will receive a confirmation SMS shortly.</p>
</ng-template>

<div class="text-center">
  <div *ngIf="isSuccess; then success else failure"></div>
</div>

<app-bottom-actions [hideContinue]="true" [continueDisabled]="true" cancelText="Close"></app-bottom-actions>
