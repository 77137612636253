import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WelcomeComponent} from './welcome/welcome.component';
import {AccountNumberSectionComponent} from './account-number-section/account-number-section.component';
import {SharedModule} from '../shared/shared.module';
import {ContentRoutingModule} from './content.routing.module';
import {TopUpHomeComponent} from './top-up-home/top-up-home.component';
import {PhoneNumberSectionComponent} from './top-up-home/phone-number-section/phone-number-section.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {VerifyPhoneNumberComponent} from './top-up-home/verify-phone-number/verify-phone-number.component';
import {TopUpAmountComponent} from './top-up-home/top-up-amount/top-up-amount.component';
import {TopUpReviewComponent} from './top-up-home/top-up-review/top-up-review.component';
import {TopUpCardLoaderComponent} from './top-up-home/top-up-card-loader/top-up-card-loader.component';
import {TopUpCardStatusComponent} from './top-up-home/top-up-card-status/top-up-card-status.component';
import {TopUpBundleSelectComponent} from './top-up-home/top-up-bundle-select/top-up-bundle-select.component';
import {TopUpBundleReviewComponent} from './top-up-home/top-up-bundle-review/top-up-bundle-review.component';
import { BillHomeComponent } from './bill-home/bill-home.component';
import { BillReviewComponent } from './bill-home/bill-review/bill-review.component';
import {BillTypeComponent} from './bill-home/bill-type/bill-type.component';
import { BillLoaderComponent } from './bill-home/bill-loader/bill-loader.component';
import { AccountNumberVerifyComponent } from './bill-home/account-number-verify/account-number-verify.component';
import {TopUpAmountBillComponent} from "./top-up-home/top-up-amount-bill/top-up-amount-bill.component";
import {FlagSelectComponent} from "./top-up-home/flag-select/flag-select.component";
import {NgxFlagIconCssModule} from "ngx-flag-icon-css";


@NgModule({
  declarations: [
    WelcomeComponent,
    AccountNumberSectionComponent,
    TopUpHomeComponent,
    PhoneNumberSectionComponent,
    VerifyPhoneNumberComponent,
    TopUpAmountComponent,
    TopUpAmountBillComponent,
    TopUpReviewComponent,
    TopUpCardLoaderComponent,
    TopUpCardStatusComponent,
    TopUpBundleSelectComponent,
    TopUpBundleReviewComponent,
    FlagSelectComponent,
    BillHomeComponent,
    BillReviewComponent,
    BillTypeComponent,
    BillLoaderComponent,
    AccountNumberVerifyComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ContentRoutingModule,
    NgxMaskModule.forRoot(),
    NgxFlagIconCssModule
  ],
  exports: [
    WelcomeComponent,
    AccountNumberSectionComponent
  ]
})
export class ContentModule { }
