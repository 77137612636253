import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SessionData} from '../models/session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {
  sessionData = new BehaviorSubject<SessionData|null>(null);

  constructor() {
    this.setSessionData({prefix: '1868'})
  }
  getSessionData(): Observable<SessionData | null> {
    return this.sessionData.asObservable();
  }

  setSessionData(newSessionData: SessionData): void {
    const currentValue = {
      ...this.sessionData.getValue(),
      ...newSessionData
    };

    this.sessionData.next(currentValue);
  }
}
